@import '../App.scss';

.basketProduct {
  width: 100%;
  margin: 25px 0;
}

.basketProduct__top {
  @include flexBetCen;
  margin-bottom: 25px;
}

.basketProduct__top__left-name {
  margin: 0;
  font-size: 14px;
  color: $light-color;
}

.basketProduct__top__left-price {
  font-size: 10px;
  color: $success-color;
}

.basketProduct__top__close {
  cursor: pointer;
  width: 12.5px;
  height: 12.5px;
  fill: $light-color;
}

.basketProduct__quantity {
  @include flexBetCen;
}

.basketProduct__quantity__count {
  @include flexCenCen;
}

.basketProduct__quantity__count-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin: 0 15px;
  fill: $success-color;
}

.basketProduct__quantity__count-icon:first-child {
  margin-left: 0;
}

.basketProduct__quantity__count-icon:last-child {
  margin-right: 0;
}

.basketProduct__quantity__count-number,
.basketProduct__quantity-total {
  font-size: 20px;
  font-weight: bolder;
}

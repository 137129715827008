@import '../../App.scss';
.address {
  cursor: pointer;
  background: $light-color;
  padding: 20px;
  width: 100%;
  overflow: hidden;
  .title {
    color: $primary-color;
    font-weight: bold;
  }
  //   .location {
  //     color: $primary-color;
  //   }
  .description {
    color: $success-color;
  }
}

.address:hover {
  background-color: $secondary-color;
}

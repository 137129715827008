@import '../App.scss';

.basket {
  width: 400px;
  margin: 65px 0 0;
  padding: 50px;
  border-radius: 10px;
  color: $light-color;
  background-color: $dark-color;
  overflow: auto;
}

.basket.noRunway {
  margin: 0;
  max-height: 90vh;
}

.basket__title {
  margin: 0;
  font-size: 18px;
  color: $light-color;
}

.basket__payDetail {
  margin: 50px 0 0;
}

.basket__payDetail__item {
  @include flexBetCen;
  width: 100%;
  font-size: 12px;
  margin: 15px 0;
}

.basket__payDetail__promotion__item {
  @include flexBetCen;
}

.basket__payDetail__promotion__item__close {
  cursor: pointer;
  height: 10px;
  fill: $light-color;
}

.basket__payDetail__total {
  @include flexBetCen;
  width: 100%;
  font-size: 15px;
  font-weight: bolder;
}

.basket__pay {
  @include flexCenCen;
  width: 100%;
}

.basket__pay-button {
  width: 150px;
  height: 25px;
  margin-bottom: 0;
  padding: 0;
  border-radius: 15px;
  color: $primary-color;
  background-color: $secondary-color;
  font-weight: bold;
}

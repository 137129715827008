@import '../App.scss';

.section {
  @include flexColCenCen;
  height: fit-content;
  width: fit-content;
}

.section__icon {
  @include flexCenCen;
  background-color: $secondary-color;
  border-radius: 50%;
  box-shadow: 0 2px 5px 0 rgba(209, 209, 209, 0.5);
  margin: 0;
  padding: 0;
  height: 120px;
  width: 120px;
}

.section__icon:hover,
.section__icon.active {
  background-color: $primary-color;
  cursor: pointer;
}

.section__icon-svg {
  fill: $dark-color;
  width: auto;
  height: 65px;
  stroke: $dark-color;
}

.section__icon:hover .section__icon-svg,
.section__icon.active .section__icon-svg {
  fill: $light-color;
  stroke: $light-color;
}

.section__title {
  color: $primary-color;
  font-size: $small-size;
  font-weight: bold;
}

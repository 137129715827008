@import '../App.scss';

.headerIcon {
  height: 25px;
  width: auto;
}

header {
  @include flexCenCen;
}

.header {
  @include flexBetCen;
  @include widthSize;
  background-color: $info-color;
  height: 50px;
  padding: 0 20px;
}

.header__menu {
  @include flexStaCen;
}

.header__menu-icon {
  margin-right: 20px;
}

.header__options {
  @include flexStaCen;
  height: inherit;
}

.header__options-item {
  @include flexStaCen;
  cursor: pointer;
  border-right: 1px solid $secondary-border-color;
  height: inherit;
  padding: 0 20px;
}

.header__options-item:first-child {
  border-left: 1px solid $secondary-border-color;
}

.header__profileMenu {
  display: none;
  position: absolute;
  top: 50px;
  right: 23px;
  background-color: $info-color;
  color: $primary-color;
  ul {
    padding: 0 17px;
    list-style-type: none;

    li {
      cursor: pointer;
      margin: 10px 0;
    }
  }
}

.header__profileMenu.active {
  display: block;
}

.header__options-cp {
  @include flexBetCen;
  font-weight: bold;
  width: 250px;
}

.header__options-cp-location {
  @include flexStaCen;
}

.header__options-cp-location-text {
  margin-left: 15px;
}

.header__options-cp-location-icon-down {
  height: 12.5px;
}

.header__options-basket-img.active {
  position: relative;
  left: 5px;
}

.header__options-basket-cont {
  @include flexCenCen;
  position: relative;
  top: -10px;
  left: -5px;
  width: 25px;
  height: 25px;
  margin: 0;
  text-align: center;
  background-color: $primary-color;
  border-radius: 50%;
  color: $light-color;
  font-size: small;
}

@import '../App.scss';

.sectionList {
  display: grid;
  grid-template-columns: repeat(7, 120px);
  grid-gap: 40px;
  margin: 50px 0;
}

.sectionList__empty {
  @include flexCenCen;
  height: 120px;
}

.sectionList__empty-icon {
  fill: $primary-color;
  margin-right: 20px;
  height: 100%;
  width: auto;
}

@import '../App.scss';

.Mobile {
  width: 100vw;
  background-color: $primary-color;
  color: $light-color;
}

.Mobile__header {
  @include flexCenCen;
  padding: 3vw;
  background-color: $info-color;
}

.Mobile__footer {
  @include flexCenCen;
  padding: 5vw;
  background-color: $info-color;
  color: $dark-color;
  font-size: 4vw;
}

.Mobile__content {
  @include flexColCenCen;
  padding: 5vw;
}

.Mobile__content__title {
  font-size: 10vw;
  text-align: center;
}

.Mobile__content__text {
  font-size: 4vw;
}

.Mobile__content__badges {
  @include flexBetCen;
  margin: 5vw 0;
  margin-bottom: 5vw;
  padding: 0 5vw;
  width: 90vw;
}

.Mobile__content__badges__item {
  margin: 0;
  width: 35vw;
  img {
    width: 35vw;
  }
}

.Mobile__content__img {
  width: 85vw;
  height: auto;
}

@import '../App.scss';

.success {
  @include flexCenSta;
  width: 100%;
  padding: 50px;
  margin: 0;
}

.success__container {
  @include flexColCenCen;
  width: 450px;
  padding: 50px 75px;
  background-color: $secondary-color;
  border-radius: 10px;

  p {
    align-self: flex-start;
  }
}

.success__title {
  margin: 35px 0;
  color: $primary-color;
}

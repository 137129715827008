@import '../../App.scss';

.forgotButton {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $primary-color;
  font-weight: bolder;
  font-size: 16px;
  align-self: center;
  width: 200px;
  height: 30px;
  background-color: $secondary-color;
  border-radius: 20px;
}

.forgotBack {
  font-weight: bolder;
  color: $primary-color !important;
}

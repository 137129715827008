@import '../App.scss';

form {
  width: 100%;
}

.addressSelect {
  @include flexColStaSta;
  width: 100%;
  padding: 35px 25px;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(194, 194, 194, 0.5);
}

.addressSelect-title {
  margin: 0 0 25px 0;
  color: $primary-color;
  font-size: 15px;
}

.addressSelectDelivery-title {
  margin: 25px 0 25px 0;
  color: $primary-color;
  font-size: 15px;
}

.addressSelect-button {
  align-self: center;
  margin: 50px 0 0 0;
  color: $light-color;
  background-color: $primary-color;
  padding: 10px;
}

.react-datepicker-wrapper {
  width: 100%;
}

@import '../App.scss';

.conditions {
	width: 70vw;
	margin: 0 auto;
	color: $primary-color;

	.conditions__title {
		text-align: center;
	}
}

.img100 {
	width: 100%;
	height: auto;
}

@import '../App.scss';

.orderItem {
  @include flexBetCen;
  cursor: pointer;
  padding: 20px;
  width: 100%;
  height: 100px;
  border-bottom: 1px solid #d8d8d8;
}

.orderItem:hover {
  background-color: $secondary-color;
}

.orderItem:last-child {
  border-bottom: none;
}

.orderItem__details__title {
  color: $primary-color;
}

.orderItem__status__text {
  margin: 0;
  text-transform: uppercase;
}

.orderItem__status__text.noPay {
  color: $danger-color;
}

@import '../App.scss';

.paymentDelete {
  @include flexColStaSta;
  padding: 20px;
}

.paymentDelete__title {
  font-size: 20px;
  color: $primary-color;
}

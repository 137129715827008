@import url(https://fonts.googleapis.com/css?family=Roboto|Roboto+Condensed&display=swap);

//Sizes
$small-size: 14px;
$smaller-size: 12px;

//Fonts
$title-font: 'Roboto Condensed', sans-serif;
$text-font: 'Roboto', sans-serif;

//Colors
$primary-color: #004e98;
$secondary-color: #e8eff6;
$secondary-border-color: #dcdcdc;
$success-color: #3da2d1;
$danger-color: #d22c26;
$warning-color: #fdbd39;
$info-color: #f8f8f8;
$light-color: #ffffff;
$dark-color: #002447;

@import '../App.scss';

form {
  width: 100%;
}

.paymentSelect {
  @include flexColStaSta;
  width: 100%;
  min-width: 500px;
  background-color: $light-color;
  padding: 35px 25px;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(194, 194, 194, 0.5);
}

.paymentSelect.selectSuccessPay,
.paymentSelect__successPay {
  @include flexColCenCen;
}

.paymentSelect-title {
  margin: 0 0 25px 0;
  color: $primary-color;
  font-size: 15px;
}

.paymentSelect-button {
  align-self: center;
  margin: 50px 0 0 0;
  color: $light-color;
  background-color: $primary-color;
}

@import '../App.scss';

.signUp {
  @include flexColCenCen;
  width: 450px;
  margin: 50px auto;
  padding: 35px 25px;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(194, 194, 194, 0.5);
  background-color: #f8f8f8;

  form {
    @include flexColCenCen;

    label,
    .inputError {
      align-self: flex-start;
    }

    label {
      color: #5b636c;
    }
  }
}

.signUp__title {
  color: $primary-color;
}

.checkbox {
  width: 25px;
  height: 25px;
  margin: 0;
  margin-right: 10px;
  border: none;
}

.termsCheck {
  @include flexCenSta;
  width: 80%;
}

.signUp__success__title {
  color: $primary-color;
}

.signUp__success__text {
  color: $dark-color;
  text-align: center;
}

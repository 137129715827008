@import '../App.scss';

.category {
  @include flexCenCen;
  background-color: $secondary-color;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(194, 194, 194, 0.5);
  color: $dark-color;
  height: 50px;
  margin: 10px 0;
  width: 215px;
  text-align: center;
}

.category:hover,
.category.active {
  @include flexCenCen;
  background-color: $primary-color;
  color: $light-color;
  cursor: pointer;
}

.category__name {
  font-size: $small-size;
  font-weight: bold;
}

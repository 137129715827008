@import '../App.scss';

.paymentNew {
  @include flexColStaSta;
  width: 100%;
  padding: 35px 25px;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(194, 194, 194, 0.5);
  background-color: $light-color;

  form {
    @include flexColStaSta;
  }
}

.paymentNew-title {
  margin: 0 0 25px 0;
  color: $primary-color;
  font-size: 15px;
}

button {
  align-self: center;
  margin: 0;
  color: $light-color;
  background-color: $primary-color;
}

.field {
  margin-bottom: 20px;
}

.paymentNew__cardDetails {
  @include flexBetCen;
  width: 100%;
}

.date {
  width: 35%;
}

.cvv {
  width: 25%;
}

.paymentNewSuccess {
  @include flexColCenCen;
  width: 100%;
}

.payCard-icon {
  fill: $primary-color;
  margin-right: 20px;
  height: 200px;
  width: auto;
}

@import '../App.scss';

.product {
  @include flexColStaBet;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(194, 194, 194, 0.5);
  margin: 15px;
  margin-top: 0;
  height: 180px;
  padding: 20px;
  width: 260px;
}

.product__name {
  font-size: $small-size;
  font-weight: bold;
}

.product__dry {
  @include flexBetCen;
  color: $primary-color;
  font-size: $smaller-size;
  width: 100%;

  .product__icon {
    fill: $primary-color;
  }
}

.product__wet {
  @include flexBetCen;
  color: $success-color;
  font-size: $smaller-size;
  width: 100%;

  .product__icon {
    width: 20px;
    height: 20px;
    fill: $success-color;
  }
}

.product__icon {
  margin: 0 5px;
}

.product__icon:hover {
  cursor: pointer;
}

.product__icon:first-child {
  margin-left: 0;
}

.product__icon:last-child {
  margin-right: 0;
}

.product__quantity {
  @include flexCenCen;
}

.product__quantity-number {
  font-size: 15px;
  font-weight: bold;
}

.product__button {
  border-radius: 5px;
  background-color: $secondary-color;
  color: $primary-color;
  font-weight: bold;
  height: 35px;
  margin: 0;
  padding: 0;
  width: 100%;
}

.product__button:hover {
  background-color: $primary-color;
  color: $light-color;
}

@import '../../App.scss';
.backPayment {
  background: $light-color;
  border-radius: 10px;
}

.nopayments {
  @include flexColCenCen;
  width: 850px;
  display: flex;
  align-items: center;
  justify-content: center;
  .message {
    max-width: 240px;
    text-align: center;
    .description {
      color: $primary-color;
    }
  }
}

.payments {
  width: 850px;
  .payments-information {
    overflow: hidden;
    background: $light-color;
    box-shadow: 0 2px 21px 0 rgba(223, 223, 223, 0.5);
    border-radius: 10px;
    .payment-item:last-child {
      border-bottom: none;
    }
    .payment-item {
      @include flexBetCen;
      margin: 0;
      padding: 20px;
      overflow: hidden;
      border-bottom: 1px solid $secondary-border-color;

      .title {
        margin-top: 0;
        color: $primary-color;
        font-weight: bold;
      }
      .description {
        margin-bottom: 0;
        span {
          background: $dark-color;
          width: 33px;
          height: 15px;
          display: inline-block;
          margin-right: 1%;
        }
      }
    }
  }
}

.payments-head {
  @include flexBetCen;
}

.payment-item:hover {
  background-color: $secondary-color;
  overflow: hidden;

  .delete {
    cursor: pointer;
    fill: $danger-color;
    width: 25px;
    height: auto;
  }
}

@import '../App.scss';

.category:first-child {
  margin-top: 0;
}

.category:last-child {
  margin-bottom: 0;
}

.categoryList__empty {
  @include flexColCenCen;
}

.categoryList__empty-icon {
  margin: 15px;
  fill: $primary-color;
  width: 50%;
}

.categoryList__empty-text {
  text-align: center;
}

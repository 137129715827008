@mixin flex {
  display: flex;
}

@mixin flexBetCen {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flexBetSta {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@mixin flexCenCen {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flexCenSta {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

@mixin flexStaCen {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@mixin flexStaEnd {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
@mixin flexStaSta {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

@mixin flexCol {
  display: flex;
  flex-direction: column;
}

@mixin flexColCenBet {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

@mixin flexColCenCen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@mixin flexColCenSta {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

@mixin flexColStaBet {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

@mixin flexColStaCen {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

@mixin flexColStaSta {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

@mixin widthSize {
  max-width: 1920px;
  min-width: 1024px;
  width: 100%;
}

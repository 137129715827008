@import '../App.scss';

.orderList__title {
  font-size: 24px;
}

.orderList {
  margin-top: 20px;
  width: 100%;
  .orderList__container {
    @include flexColCenCen;
    border-radius: 10px;
    overflow: hidden;
    width: 750px;
    box-shadow: 0 2px 21px 0 rgba(223, 223, 223, 0.5);
  }
}

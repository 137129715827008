@import '../App.scss';

.productList {
  @include flexStaSta;
  flex-wrap: wrap;
}

.productList__empty {
  @include flexCenCen;
  height: 120px;
}

.productList__empty-icon {
  fill: $primary-color;
  margin-right: 20px;
  height: 100%;
  width: auto;
}

.Modales{
    
  width: 10%;
      background: #e8eff6;
      border: 1px solid silver;
      padding: 10px;
      height: 42px;
      margin-top: -43px;
      border-radius: 50px;
      cursor: pointer;
    }

    .modalleft{
      margin-left: 190px;
    }


    
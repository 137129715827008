@import '../App.scss';

.profileEdit {
  width: 500px;
  max-height: 90vh;
  padding: 35px 25px;
  border-radius: 10px;
  background-color: $light-color;
  overflow: auto;
}

.profileEdit__title {
  font-size: 20px;
  color: $primary-color;
}

.profileEdit__success {
  @include flexColCenSta;
}

.profileSuccess-icon {
  width: 200px;
  height: auto;
  fill: $primary-color;
}

/* Chrome, Safari, Edge, Opera */
.phone::-webkit-outer-spin-button,
.phone::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.phone[type='number'] {
  -moz-appearance: textfield;
}

@import '../App.scss';

.Promotion {
  @include flexColCenSta;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(194, 194, 194, 0.5);
  margin: 15px;
  margin-top: 0;
  height: 180px;
  padding: 20px;
  width: 260px;
  display: inline-block;
  text-align: center;
}

.Promotion:hover {
  background-color: $secondary-color;
}

.Promotion__icon {
  width: auto;
  height: 50px;
  fill: $primary-color;
}

.Promotion__name {
  font-size: 16px;
}

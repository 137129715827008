@import '../../App.scss';
.navbar-items {
  @include flexStaCen;
  background-color: $secondary-color;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(194, 194, 194, 0.5);
  color: $dark-color;
  height: 50px;
  margin: 10px 0;
  width: 215px;
  padding: 15px;
  cursor: pointer;
  font-weight: bolder;
  font-size: 14px;
  &.active {
    background-color: $primary-color;
    color: $light-color;
    .menu-item {
      svg {
        fill: $light-color;
      }
    }
  }
  .menu-item {
    @include flexStaCen;
    svg {
      width: auto;
      height: 25px;
      margin-right: 10px;
      fill: $primary-color;
    }
  }
}

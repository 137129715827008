@import '../App.scss';

.content {
  @include widthSize;
  margin: 0 auto;
}

@media screen and (max-width: 425px) {
  .content {
    min-width: 0;
    width: 100vw;
  }
}

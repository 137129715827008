@import '../App.scss';

.confirm {
  @include flexCenSta;
  width: 100%;
  padding: 50px;
  margin: 0;
}

.confirm__details {
  width: 720px;
  padding: 50px;
  box-shadow: 0 2px 5px 0 rgba(209, 209, 209, 0.5);
  border-radius: 10px;
  background-color: $secondary-color;
}

.confirm__resume {
  @include flexColStaSta;
  margin-left: 50px;
  width: 280px;
  padding: 50px 50px;
  box-shadow: 0 2px 5px 0 rgba(209, 209, 209, 0.5);
  border-radius: 10px;
  background-color: $secondary-color;
}

.confirm__title {
  color: $primary-color;
  margin-top: 0;
}

h4.confirm__title {
  color: $primary-color;
  margin: 5px 0;
}

h2.confirm__title {
  color: $primary-color;
  margin: 35px 0 5px;
}

.confirm__addresses {
  @include flexCenSta;
  width: 100%;
}

.confirm__addresses__item {
  width: 33.33%;
  padding: 5px;
}

.confirm__products__item {
  width: 100%;
  padding: 10px 0;
}

.confirm__subtotal {
  @include flexBetSta;
  width: 100%;
}

.confirm__total {
  align-self: flex-end;
}

.confirm__button {
  width: 100%;
  margin-top: 50px;
}

a {
  .confirm__button {
    width: 100%;
    margin-top: 50px;
  }
}

@import '../App.scss';

.order {
  @include flexStaSta;
}

.order__section {
  @include flexColStaSta;
  padding: 50px;
  margin: 0;
  width: 600px;
}

.order__section__stepper {
  @include flexCenSta;
  width: 100%;
  margin-bottom: 25px;
}

.order__section__stepper-step {
  @include flexStaCen;
  width: 50%;
  height: 40px;
  padding: 10px;
  font-size: 15px;
  background-color: $info-color;
  color: $dark-color;
}

.order__section__stepper-step.active {
  background-color: $dark-color;
  color: $light-color;
}

@import '../App.scss';

footer {
  @include flexCenCen;
}

.footer {
  @include widthSize;
  background-color: $info-color;
  color: $dark-color;
  font-size: $small-size;
  padding: 35px 75px;
}

.footer__container {
  @include flexBetSta;
}

.footer__item {
  @include flexColStaSta;
}

.footer__item-text {
  margin: 7px 0;
}

.footer__rights {
  margin-top: 30px;
}

.footer__newsletter-title {
  text-align: center;
}

.width300 {
  width: 300px;
}

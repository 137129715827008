@import '../../App.scss';

.auth-container {
  margin: 50px auto;
  padding: 35px 25px;
  border-radius: 10px;
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px 0 rgba(194, 194, 194, 0.5);
  background-color: $info-color;

  h1 {
    color: $primary-color;
    font-weight: bolder;
  }
}

.login__label {
  color: #5b636c;
}

.loginButton {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $primary-color;
  font-weight: bolder;
  font-size: 16px;
  align-self: center;
  width: 200px;
  height: 30px;
  background-color: $secondary-color;
  border-radius: 20px;
}

.passRecovery {
  font-weight: bolder;
  color: $primary-color !important;
}

.network,
.signup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.swal-footer {
  text-align: center !important;
}

.swal-button {
  background: #003a75;
}
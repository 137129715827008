@import '../../App.scss';
.addresses {
  width: 850px;
  overflow: hidden;
  .addresses-container {
    overflow: hidden;
    border-radius: 10px;
    width: 100%;
    box-shadow: 0 2px 21px 0 rgba(223, 223, 223, 0.5);

    .address-container {
      border-bottom: 1px solid $secondary-border-color;
    }

    .address-container:last-child {
      border-bottom: none;
    }
  }
}

.noaddresses {
  @include flexColCenCen;
  width: 850px;
  display: flex;
  align-items: center;
  justify-content: center;
  .image {
    height: 125px;
    fill: $primary-color;
  }
  .message {
    max-width: 240px;
    text-align: center;
    .description {
      color: $primary-color;
    }
  }
}

.addresses-head {
  @include flexBetCen;
}

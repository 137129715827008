@import '../App.scss';

.productAdd {
  @include flexColCenBet;
  width: 325px;
  padding: 35px;
  border-radius: 10px;
  background-color: $light-color;
  font-size: 14px;
}

.productAdd__title {
  margin-bottom: 20px;
  font-size: 16px;
  text-align: center;
  color: $primary-color;
}

.productAdd__text {
  margin-bottom: 25px;
}

.productAdd__button {
  width: 100%;
  height: 30px;
  margin: 5px 0;
  padding: 0;
  color: $primary-color;
  background-color: $secondary-color;
  font-weight: bolder;
}

@import '../App.scss';

.orderDetail__root {
  overflow: auto;
  max-height: 90vh;
  border-radius: 10px;
}

.orderDetail {
  @include flexColCenSta;
  width: 450px;
  padding: 25px 50px;
  background-color: $info-color;
}

.orderDetail__title {
  align-self: flex-start;
  margin: 0;
  color: $primary-color;
  font-size: 18px;
}

.orderDetail__card {
  width: 100%;
  margin: 25px;
  margin-top: 0;
  padding: 15px;
  background-color: $light-color;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(194, 194, 194, 0.5);
  p {
    font-size: 12.5px;
  }
}

.orderDetail__noPayText {
  color: $danger-color;
}

.orderDetail__card:first-child {
  margin-top: 25px;
}

.orderDetail__card:last-child {
  margin-bottom: 0;
}

.orderDetail__title,
.orderDetail__card__title {
  margin-bottom: 15px;
}

.orderDetail__card__container {
  margin-top: 15px;
}

.orderDetail__statusList {
  @include flexStaEnd;
}

.orderDetail__statusList__bullet__circle {
  margin: 0;
  padding: 0;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background-color: $dark-color;
}

.orderDetail__statusList__bullet__line {
  position: relative;
  left: 4px;
  margin: 0;
  padding: 0;
  width: 2px;
  height: 20px;
  background-color: $dark-color;
}

.orderDetail__statusList__bullet__circle.active,
.orderDetail__statusList__bullet__line.active {
  background-color: #7ed321;
}

.orderDetail__statusList__item {
  margin: 0;
  margin-left: 10px;
  font-size: 12.5px;
  font-weight: bolder;
  color: #808d99;
  margin-bottom: -3px;
}

.orderDetail__resume {
  width: 100%;
  padding: 25px 75px;
  background-color: $dark-color;
  color: $light-color;
}

.orderDetail__product {
  @include flexBetCen;
  margin: 10px 0;
}

.orderDetail__product__wrapper {
  @include flexStaSta;
}

.orderDetail__product__quantity {
  font-weight: bolder;
  margin-left: 10px;
  margin-right: 15px;
}

.orderDetail__product__detail {
  @include flexColStaCen;
}

.orderDetail__product__detail__name {
  font-size: 16px;
}

.orderDetail__product__detail__section {
  font-size: 12px;
  color: $success-color;
}

.orderDetail__product__detail__section.wet {
  font-size: 12px;
  color: #7ed321;
}

.orderDetail__product__total {
  font-weight: bolder;
}

.orderDetail__resume__item {
  @include flexBetCen;
  font-size: 14px;
}

.orderDetail__resume__total {
  @include flexBetCen;
  font-size: 16px;
  font-weight: bolder;
}

@import '../App.scss';

.PromotionDetail {
  @include flexCol;
  width: 400px;
  max-height: 90vh;
  overflow: auto;
  border-radius: 10px;
  padding: 50px;
  background-color: $light-color;
}

.PromotionDetail__icon {
  align-self: center;
  fill: $primary-color;
  height: 100px;
}

.PromotionDetail__name {
  align-self: center;
  color: $dark-color;
}

.PromotionDetail__warningText {
  text-align: center;
  margin-bottom: 0;
}

.PromotionDetail__button {
  margin-top: 16px;
  font-size: 16px;
}

pre{
  font-family: "Roboto", sans-serif;
}

.TextoDesc{
  font-size: 18px;
  text-align: center;
}
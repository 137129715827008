@import '../App.scss';

.DescRenovacion__name {
  display: flex; 
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 10px;
  margin: 15px;
  margin-top: 0;
  height: 50px;
  padding: 15px;
  width: 100px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #e8eff6;
  font-size: 14px;
  text-align: center;
}



  .leftsaber{
    display: revert;
    margin-top: -65px;
    margin-left: 130px;
  }

  .PromotionDetail__warningText {
    color: $primary-color;
    font-family: "Roboto", sans-serif;
  }





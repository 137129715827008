@import '../../App.scss';
.my-profile {
  width: 850px;
  .my-information {
    background: $light-color;
    box-shadow: 0 2px 21px 0 rgba(223, 223, 223, 0.5);
    border-radius: 10px;
    .my-profile-item:last-child {
      border-bottom: none;
    }
    .my-profile-item {
      padding: 20px;
      margin: 0px 0px;
      border-bottom: 1px solid $secondary-border-color;
      .title {
        color: $primary-color;
        font-weight: bold;
      }
    }
  }
}

.myProfile__head {
  @include flexBetCen;
}

@import '../../App.scss';
.newAddress-container {
  border-radius: 10px;
  overflow: auto;
  padding: 35px;
  max-height: 90vh;
  background-color: $light-color;
}
.new-address {
  width: 400px;

  .map-container {
    position: relative;
    min-width: 100%;
    height: 200px;
  }
}

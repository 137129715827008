@import '../App.scss';

.error {
  @include flexCenCen;
  width: 100%;
}

.error.vertical {
  @include flexColCenCen;
  width: 100%;

  .error-icon {
    margin-right: 0;
  }
}

.error-icon {
  fill: $primary-color;
  margin-right: 20px;
  height: 120px;
  width: auto;
}

.error-text {
  text-align: center;
  margin: 0;
}

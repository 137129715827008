@import './Mixins.scss';
@import './Vars.scss';

* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: $success-color;
}

a:visited {
  color: inherit;
}

body {
  background-color: $light-color;
  font-family: $text-font;
  margin: 0;
  min-width: 1024px;
}

button {
  border: none;
  padding: 14px;
  border-radius: 5px;
}

button:hover {
  cursor: pointer;
}

h1,
h3,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

input {
  border: none;
  color: inherit;
  padding: 0 15px;
}

section {
  padding: 0 50px;
  margin-bottom: 50px;
}

select,
input {
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(194, 194, 194, 0.5);
}

:focus {
  outline: none;
}

.btn-dark {
  background-color: $dark-color;
  color: $light-color;
}

.flexStaSta {
  @include flexStaSta;
}

.link {
  color: inherit;
  text-decoration: none;
}

.link:hover {
  color: $success-color;
}

.link:visited {
  color: inherit;
}

.inputError {
  color: $danger-color;
}

.margin50 {
  margin-top: 50px;
}

.newLink {
  margin-top: 25px;
  cursor: pointer;
  color: $primary-color;
}

.submit:disabled {
  cursor: default;
  opacity: 50%;
}

.width100 {
  width: 100%;
}

@media screen and (max-width: 425px) {
  body {
    min-width: 0;
    width: 100%;
  }
}

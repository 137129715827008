@import '../App.scss';

.products {
  padding-left: 0;
  width: 100%;
}

.products__header {
  @include flexBetCen;
  margin-bottom: 35px;
}

.products__header-icon {
  fill: $primary-color;
}

.products__header-icon:hover {
  cursor: pointer;
}

.products__header-text {
  font-size: $small-size;
  font-weight: bold;
  margin-top: 0;
}

@import '../App.scss';

.billForm {
  @include flexColCenSta;
  padding: 35px 25px;
  max-height: 90vh;
  overflow: auto;
  border-radius: 10px;
  background-color: $light-color;

  h1 {
    color: $primary-color;
    font-size: 20px;
  }
}
